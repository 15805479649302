import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next";

const Contact = () => {
    const { t } = useTranslation();

    return (
    <div className="py-12 lg:py-16 text-lg" id="contact">
        <div className="grid grid-cols-1 sm:grid-cols-3">
            <div className="hidden w-100 sm:block">
                <StaticImage src="../images/GB-interieur1.jpg" alt=""/>            
            </div>
            <div className="col-span-2 p-12 bg-gray-100">
                <div className="mb-6">
                    <h2 className="section-title">
                     {t("contact.booking.title")}
                    </h2>
                    <p>{t("contact.booking.info")}</p>
                    <a href="https://bookings.zenchef.com/results?rid=356254" target="_blank" className="font-medium px-3 py-1 inline-block mt-1 text-xl border border-gray-700 pb-2 text-gray-700 hover:text-white hover:bg-black  transition duration-150 ease-in-out">
                        {t("contact.booking.link")}
                    </a>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div>
                        <h2 className="section-title">
                        {t("contact.findus.title")}
                        </h2>
                        <h3 className="section-subtitle">{t("contact.findus.address.title")}</h3>
                        <p className="section-content">
                        {t("contact.findus.address.content")}<br/> 
                        {t("contact.findus.address.content2")}
                        </p>
                        <h3 className="section-subtitle">{t("contact.findus.subway.title")}</h3>
                        <p className="section-content">
                        Bastille <br/>
                        Saint-Paul
                        </p>
                        <h3 className="section-subtitle">{t("contact.findus.phone.title")}</h3>
                        <p className="section-content">
                        +33 (0)9 75 80 99 72                        
                        </p>
                        <h3 className="section-subtitle">Email</h3>
                        <p className="section-content">
                        <a href="mailto:contact@grandebrasserie.fr">contact@grandebrasserie.fr</a>
                        </p>
                    </div>
                    <div>
                        <h2 className="section-title">
                        {t("contact.findus.hours.title")}
                        </h2>
                        <h3 className="section-subtitle">{t("contact.findus.hours.lunch")}</h3>
                        <p className="section-content">                            
                        {t("contact.findus.hours.lunch.days")} <br/>{t("contact.findus.hours.lunch.hours")}
                        </p>
                        <h3 className="section-subtitle">{t("contact.findus.hours.dinner")}</h3>
                        <p className="section-content">                            
                        {t("contact.findus.hours.dinner.days")} <br/>{t("contact.findus.hours.dinner.hours")}
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>        
    </div> 
)}

export default Contact

