import React, { useContext } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import {I18nextContext} from 'gatsby-plugin-react-i18next';
import Carousel from "./Carousel"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Bold = ({ children }) => <span className="font-bold">{children}</span>
const Text = ({ children }) => <p className="text-xl">{children}</p>
const Heading2 = ({ children }) => <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 leading-tight tracking-tight">{children}</h2>

const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <Heading2>{children}</Heading2>,
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
            <div className="flex justify-center">
                <img
                    src={`${node.data.target.file.url}`}            
                    alt=""            
                />
            </div>
        )
      },
    },
  }

  
const About = () => {
    const context = useContext(I18nextContext);
    const currentLanguage = context.language === 'fr' ? 'fr' : 'en-US';
    
    
    
    
    
    const data = useStaticQuery(graphql`
        query  {
            allContentfulAbout {
                edges {
                    node {
                        title
                        node_locale
                        gallery {
                            gatsbyImageData( placeholder: BLURRED, formats: AUTO)
                            title
                        }
                        content {
                            raw
                            references {
                                ... on ContentfulAsset {
                                    __typename
                                    contentful_id
                                    file {
                                        url
                                        details{
                                            size
                                            image{
                                                width
                                                height
                                            }
                                        }
                                        fileName
                                        contentType
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)    
    

    const dataLang =  data.allContentfulAbout.edges.filter(function(about) {        
        return about.node.node_locale === currentLanguage;
    });
    
        
    const bodyRichText  = dataLang[0].node.content

    return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4" id="about">
                        
        <div className="p-12 flex h-full justify-center items-center " >
            <div className="space-y-6 text-center">{bodyRichText && renderRichText(bodyRichText, options)}</div>                    
        </div>
        <div className="sm:p-24" >        
            <Carousel images={dataLang[0].node.gallery} />
        </div>
    </div>
    )
}

export default About

