import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import About from "../components/About";
import Contact from "../components/Contact";
import Hero from "../components/Hero";
import Menu from "../components/Menu";
import Layout from "../layouts/Layout";
import Photos from "../components/Photos";
import Social from "../components/Social";
import SEO from "../components/Seo";

const IndexPage = ({ data }) => {
  const { t } = useTranslation();

  //console.log('locales' , data.locales)
  return (
    <Layout>
      <SEO title={t("home")}/>
      <main className="relative">        
        <Hero />
        <About />
        <Menu />
        <Contact />
        <Photos />
        <Social />
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
