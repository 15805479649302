import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "swiper/css"
import 'swiper/css/navigation';
import '../styles/swiper.css'


const Carousel = (props) => {
    const {        
        images,        
      } = props
    
    return (
    <Swiper 
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}        
        navigation                
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
        >
            {images.map((item, index) => {
                const image = getImage(item)
                //console.log('Carousel image', image)
                if(image){
                    return (
                        <SwiperSlide key={`${item.title}-${index}`}>
                            <GatsbyImage image={image} alt={item.title} />                            
                        </SwiperSlide>
                    )
                }
                return null
            })}        
    </Swiper>
    )
};
export default Carousel;