import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next";

const Social = () => {
    const { t } = useTranslation();

    return (
  
    <div className="container px-3 py-12 lg:py-16" id="social">
        <div className="">            
            <h2 className="section-title">{t("social.title")} </h2>
            <div className="section-subtitle">
                Instagram <a href="https://www.instagram.com/grande_brasserie_bastille/" target="_blank" className="hover:underline hover:text-gray-700">  @grande_brasserie_bastille</a>
            </div>
            <div className="section-subtitle">
            Facebook <a href="https://www.facebook.com/Grandebrasseriebastille/" target="_blank" className="hover:underline hover:text-gray-700"> @Grandebrasseriebastille</a>    
            </div>
        </div>        
    </div>    
)}

export default Social

