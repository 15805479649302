import React, { useContext } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import {I18nextContext} from 'gatsby-plugin-react-i18next';
import Carousel from "./Carousel"

  
const Photos = () => {
    const context = useContext(I18nextContext);
    //console.log('context', context.language)
    const currentLanguage = context.language === 'fr' ? 'fr' : 'en-US';
    
    

    const data = useStaticQuery(graphql`
        query  {
            allContentfulPhotos {
                edges {
                    node {
                        title
                        node_locale
                        gallery {
                            gatsbyImageData( placeholder: BLURRED, formats: AUTO)
                            title
                        }                        
                    }
                }
            }
        }
    `)
    /* if(data){
        console.log('data', data)
    } */
    
    

    const dataLang =  data.allContentfulPhotos.edges.filter(function(about) {        
        return about.node.node_locale === currentLanguage;
    });
    
    
    //console.log('dataLang', dataLang)
        

    return (
        <div className="max-h-50" id="photos">                                        
            <Carousel images={dataLang[0].node.gallery} />        
        </div>
    )
}

export default Photos

