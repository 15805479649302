import React, { useContext } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import {I18nextContext} from 'gatsby-plugin-react-i18next';

const Bold = ({ children }) => <span className="font-bold">{children}</span>
const Text = ({ children }) => <p>{children}</p>
const Heading2 = ({ children }) => <h2 className="section-title mt-4">{children}</h2>

const List = ({content}) => {
    //console.log('Li1 props->', content)
    
    return (
        <tr>
        {content.content.map((item, index) => {
            if(item.nodeType === 'paragraph'){
                return (<td key={`${item.nodeType}-${index}`}>{item.content[0].value}</td>)
            }else{
                return (<td key={`${item.nodeType}-${index}`}>{item.content[0].content[0].content[0].value}</td>)
            }            
        }
        )}            
        </tr>
)}
const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {    
        [BLOCKS.HEADING_2]: (node, children) => <Heading2>{children}</Heading2>,  
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>, 
        [BLOCKS.UL_LIST]: (node, children) => {        
            return (
                <table className="table-fixed w-full">
                <thead>
                    <tr>                
                    <th className="w-10/12"></th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {node.content.map((item, index) => (<List key={`${item.nodeType}-${index}`} content={item}/>))}            
                </tbody>
                </table>            
            )
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        //console.log('BLOCKS.EMBEDDED_ENTRY node->', node)
        //console.log('INLINES.EMBEDDED_ENTRY children->', children)
            return (
                <>
                    <h3 className="section-subtitle">{node.data.target.title}</h3>
                    {renderRichText(node.data.target.content, options)}
                </>          
            )
        },      
        [INLINES.ASSET_HYPERLINK]: (node) => {
            //console.log('INLINES.ASSET_HYPERLINK node->', node)
            //console.log('INLINES.EMBEDDED_ENTRY children->', children)
            return (            
                    <a href={node.data.target.file.url} target="_blank" className="link">{node.content[0].value}</a>                                            
            )
        },                    
    },
  }

const Menu = () => {
    const context = useContext(I18nextContext);
    //console.log('context', context.language)
    const currentLanguage = context.language === 'fr' ? 'fr' : 'en-US';

    const data = useStaticQuery(graphql`
        query  {
            allContentfulMenu {
                edges {
                    node {
                        title
                        node_locale                        
                        leftColumn {
                            raw
                            references{
                                ... on ContentfulMenuItem{
                                    contentful_id
                                    __typename
                                    node_locale
                                    title
                                    content {
                                        __typename
                                        ... on ContentfulMenuItemContent{
                                            raw
                                        }
                                        raw
                                    }
                                }
                            }
                        }
                        rightColumn {
                            raw
                            references{
                                ... on ContentfulAsset {
                                    contentful_id
                                    __typename
                                    title
                                    children{
                                        __typename
                                        internal{content}
                                    }
                                    file {
                                        fileName
                                        url
                                    }
                                }
                                ... on ContentfulMenuItem{
                                    contentful_id
                                    __typename
                                    node_locale
                                    title
                                    content {
                                        __typename
                                        ... on ContentfulMenuItemContent{
                                            raw
                                        }
                                        raw
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    // if(data){
    //     console.log('data', data)
    // }

    const dataLang =  data.allContentfulMenu.edges.filter(function(menu) {        
        return menu.node.node_locale === currentLanguage;
    });
    
    const leftColumn  = dataLang[0].node.leftColumn
    const rightColumn  = dataLang[0].node.rightColumn
    //console.log('leftColumn', leftColumn)
    //console.log('rightColumn', rightColumn)
        

    return (
        <div className="container px-3 py-12 lg:py-16" id="menu">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">            
                <div >
                    {leftColumn && renderRichText(leftColumn, options)}         
                </div>
                
                <div className="pt-7">                                
                    {rightColumn && renderRichText(rightColumn, options)}  
                </div>
            </div>        
        </div>    
    )
}

export default Menu

