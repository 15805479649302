import React from "react"
import {SiFacebook, SiInstagram} from "react-icons/si"
const Footer = () => {
  
  return (
    <div className="flex justify-between bg-gray-200 px-5 py-2">
     <div className="flex items-center"><span className="">Grande Brasserie</span>  </div>
     <div className="flex items-center">6 rue de la Bastille <br/>75004 Paris </div>
     <div className="flex items-center text-2xl">       
     <a href="https://www.facebook.com/Grandebrasseriebastille/" target="_blank" className="mx-2">
        <SiFacebook /> 
      </a>
      <a href="https://www.instagram.com/grande_brasserie_bastille/" target="_blank">
        <SiInstagram/>
        </a>
      </div>
    </div>
  )
}


export default Footer
