import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import background from "../images/GB-home.jpg";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { motion } from "framer-motion";
import Splash from "./Splash";

const Hero = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(true);
  return (
    <>
      {/* <motion.div
      animate={{        
        backgroundColor: "#000",
        boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)",        
        transitionEnd: {
          display: "none",
        },
      }}
    >
    <div className="flex h-screen z-5 w-full">        
        <div className="container m-auto">        
            <div className="flex items-center pb-5">
                <StaticImage src="../images/GB-title.png" alt="Grande Brasserie - Paris 4"/>
            </div>        
        </div>        
    </div>
  </motion.div> */}

      <div
        className="min-h-screen bg-cover bg-no-repeat bg-center bg-fixed z-10"
        style={{ backgroundImage: `url(${background})` }}
      >
        {/* <div className="invisible sm:visible sticky flex justify-center py-5 ">
        <AnchorLink                            
            className="px-3 py-2 text-2xl bg-opacity-30 bg-gray-700 font-medium border border-white pb-2 text-white hover:text-white hover:bg-gray-700 transition duration-150 ease-in-out"
            activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
            to="#contact"
        >
          Réservation
        </AnchorLink>      
      </div> */}
        <div className="container w-40  h-screen flex items-center justify-center">
          <StaticImage
            src="../images/GB-logo.png"
            alt="Grande Brasserie - Paris 4"
          />
        </div>
      </div>

      
    </>
  );
};

export default Hero;
