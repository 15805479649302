import React, { useState, useRef } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FaBars } from "react-icons/fa";
import { graphql, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import MenuMobile from "./MenuMobile";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { languages, originalPath, changeLanguage, language } = useI18next();
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const { t } = useTranslation();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  //const pos = useScrollPosition();
  
  //console.log('hideOnScroll', hideOnScroll)

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `);

  return (
    // <header className="sticky z-20 inset-x-0 top-0 left-0 bg-gray-400 py-5">
    <header className="fixed top-0 z-50 w-full bg-opacity-50 bg-gray-300">
      <div className="container pt-2 pb-2">
        <div className="flex justify-between  px-10">
          <AnchorLink
            className="px-3 py-2 text-2xl bg-opacity-30 bg-gray-700 font-medium border border-white pb-2 text-white hover:text-white hover:bg-gray-700 transition duration-150 ease-in-out"
            activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
            to="#contact"
          >
            {t("booking")}
          </AnchorLink>          
          <div className="flex items-center">
          <ul className="mr-4 flex items-center space-x-1">
            {languages.map((lng, k) => (
                <li key={lng}>
                  {k > 0 &&
                  '/ '
                  }
                    <a
                        href="/"
                        onClick={() => {
                          changeLanguage(lng);
                        }}>
                        {lng}
                    </a>
                </li>          
                ))}
          </ul>

          <button onClick={() => setIsMenuOpen(true)} aria-label="Open Menu">
            <FaBars className="h-6 w-auto text-gray-900 fill-current -mt-1" />
          </button>          
          </div>
          
        </div>
      </div>
      <MenuMobile
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
        links={site.data.menu}
      />
    </header>
  );
};

export default Header;
